import { render, staticRenderFns } from "./SvgDefs.vue?vue&type=template&id=c63c6f8c&scoped=true&functional=true&"
var script = {}
function injectStyles (context) {
  
  var style0 = require("./SvgDefs.vue?vue&type=style&index=0&id=c63c6f8c&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  injectStyles,
  "c63c6f8c",
  "9e22449a"
  
)

export default component.exports